import { FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
import AppleBadgeBlack from "../../assets/apple_badge_black.svg";
import GooglePlayBadge from "../../assets/google_play_badge.png";

export const footerDataCompany = {
  header: "company",
  links: [
    {
      label: "About",
      to: "/about",
    },
    {
      label: "Imprint",
      to: "/imprint",
    },
    {
      label: "Privacy",
      to: "/privacy",
    },
  ],
};

export const footerDataFollowUs = {
  header: "follow us",
  links: [
    {
      label: "Twitter",
      to: "https://twitter.com/nuddleapp",
      icon: (
        <FaTwitter
          style={{
            color: "var(--custom-white)",
          }}
        />
      ),
    },
    {
      label: "Instagram",
      to: "https://instagram.com/nuddleapp",
      icon: <FaInstagram style={{ color: "var(--custom-white)" }} />,
    },
    {
      label: "Facebook",
      to: "https://facebook.com/nuddleapp",
      icon: <FaFacebook style={{ color: "var(--custom-white)" }} />,
    },
  ],
};

export const footerDataDownload = {
  header: "download",
  links: [
    {
      img: AppleBadgeBlack,
      to: "https://apps.apple.com/app/nuddle-number-sequences/id1643422440",
    },
    {
      img: GooglePlayBadge,
      to: "https://play.google.com/store/apps/details?id=com.weiss.nuddle",
    },
  ],
};

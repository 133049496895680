import styled from "styled-components";

export const GlobalDownloadWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GlobalDownloadBadge = styled.img`
  height: 50px;

  @media screen and (max-width: 480px) {
    height: 40px;
  }
`;

import styled from "styled-components";

export const VerticalSpace = styled.div`
  height: ${(props) => props.vs};
`;

export const HorizontalSpace = styled.div`
  width: ${(props) => props.hs};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  max-width: 1100px;
  background: #000;
  margin: 25px 0;
  display: flex;
  opacity: 0.1;

  @media screen and (min-width: 768px) {
    display: ${(props) => (props.onlyOnMobile ? null : "none")};
  }
`;

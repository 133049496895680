import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const GlobalTextLinkInternal = styled(NavLink)`
  cursor: pointer;
  color: ${(props) =>
    props.light ? "var(--custom-white-opacity)" : "var(--primary-color)"};
  transition: all 0.5s;
  font-size: 13px;
  text-decoration: none;
  padding: 3px 0;

  &:hover {
    color: ${(props) =>
      props.light ? "var(--custom-white)" : "var(--secondary-color)"};
  }
`;

export const GlobalTextLinkExternal = styled.a`
  cursor: pointer;
  color: ${(props) =>
    props.light ? "var(--custom-white-opacity)" : "var(--primary-color)"};
  transition: all 0.2s ease;
  font-size: 13px;
  padding: 5px 0;
  text-decoration: none;

  &:hover {
    color: ${(props) =>
      props.light ? "var(--custom-white)" : "var(--secondary-color)"};
  }
`;

import styled, { keyframes, css } from "styled-components";
import { FaArrowDown } from "react-icons/fa";

const shakeAnimation = keyframes`
    0% { transform: translate(2px, 0px) }
    10% { transform: translate(-2px, 0px) }
    20% { transform: translate(2px, 0px) }
    30% { transform: translate(-2px, 0px)  }
    40% { transform: translate(2px, 0px) }
    50% { transform: translate(-2px, 0px)  }
    60% { transform: translate(2px, 0px) }
    70% { transform: translate(-2px, 0px) }
    80% { transform: translate(2px, 0px)  }
    90% { transform: translate(-2px, 0px) }
    100% { transform: translate(0px, 0px)  }
`;

const shakeAnimation2 = keyframes`
    0% { transform: translate(-2px, 0px) }
    10% { transform: translate(2px, 0px) }
    20% { transform: translate(-2px, 0px) }
    30% { transform: translate(2px, 0px)  }
    40% { transform: translate(-2px, 0px) }
    50% { transform: translate(2px, 0px)  }
    60% { transform: translate(-2px, 0px) }
    70% { transform: translate(2px, 0px) }
    80% { transform: translate(-2px, 0px)  }
    90% { transform: translate(2px, 0px) }
    100% { transform: translate(0px, 0px)  }
`;

const enter = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

const update = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

const pulse = keyframes`
0% {
		opacity: 1;
	}

	50% {
		opacity: 0.2;
	}

	100% {
		opacity: 1;
	}
`;

export const NuddleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--primary-color);
`;

export const NuddleContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1100px;
  width: 100%;
  flex-direction: column;
  padding: 80px 20px;
  margin-bottom: 50px;
`;

export const NuddleSequenceGrid = styled.div`
  display: grid;
  width: 100%;
  gap: 5px;
  max-width: 600px;
  margin-bottom: 40px;
  grid-template-columns: repeat(8, 1fr);
`;

export const NuddleNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  border: ${(props) =>
    props.active
      ? "1px solid var(--custom-white)"
      : "1px solid var(--custom-white-opacity-2)"};
  animation: ${(props) =>
    props.toggle
      ? css`
          ${shakeAnimation} 0.7s linear forwards
        `
      : css`
          ${shakeAnimation2} 0.7s linear forwards
        `};
  height: calc((600px - 30px) / 8);

  @media screen and (max-width: 600px) {
    height: calc((100vw - 70px) / 8);
  }
`;

export const NuddleNumber = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: var(--custom-white);
`;

export const NuddleKeyboardGrid = styled.div`
  display: grid;
  gap: 5px;
  width: 100%;
  max-width: 300px;
  grid-template-columns: repeat(5, 1fr);
`;

export const KeyboardButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: var(--secondary-color);
  color: #fff;
  height: calc((300px - 30px) / 5);

  @media screen and (max-width: 300px) {
    height: calc((100vw - 30px) / 5);
  }

  &:hover {
    background: var(--primary-color);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 7px 29px 0px;
  }
`;

export const EnterButton = styled.div`
  cursor: pointer;
  background: var(--secondary-color);
  // grid-column: 2 / 1;
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  grid-column: 5 / -6;
  height: calc((300px - 30px) / 5);

  @media screen and (max-width: 300px) {
    height: calc((100vw - 30px) / 5);
  }

  &:hover {
    background: var(--primary-color);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 7px 29px 0px;
  }
`;

export const DeleteButton = styled.div`
  cursor: pointer;
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  background: var(--secondary-color);
  //grid-column: 1/5;
  transition: all 0.4s ease;
  height: calc((300px - 30px) / 5);

  @media screen and (max-width: 300px) {
    height: calc((100vw - 30px) / 5);
  }

  &:hover {
    background: var(--primary-color);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 7px 29px 0px;
  }
`;

export const ScrollIcon = styled(FaArrowDown)`
  color: var(--custom-white-opacity);
  height: 25px;
  width: 25px;
  margin: 10px;
  opacity: 1;
  animation: ${pulse} 2s infinite ease;
`;

// create the spy image that looks around
export const SpyWrapper = styled.div`
  display: grid;
  place-items: center;
  height: 75px;
  position: relative;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const SpyImage = styled.img`
  height: 75px;
  //position: absolute;
`;

export const EyeWrapper = styled.div`
  position: absolute;
  height: 75px;
`;

export const EyeImg = styled.img`
  position: absolute;
  height: 25px;
`;

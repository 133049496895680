import styled from "styled-components";

export const GlobalSmallText = styled.p`
  color: ${(props) =>
    props.light ? "var(--custom-white-opacity)" : "var(--primary-color)"};
  font-size: 11px;
  text-align: ${(props) => (props.center ? "center" : "right")};
  width: 100%;
`;

export const GlobalSubtitleSmall = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
  padding-bottom: 12px;
  text-transform: uppercase;
  color: ${(props) =>
    props.light ? "var(--custom-white)" : "var(--primary-color)"};

  @media screen and (max-width: 768px) {
    display: ${(props) => (props.hiddenOnMobile ? "none" : null)};
  }
`;

export const GlobalHeader1 = styled.h1`
  font-size: 48px;
  color: ${(props) =>
    props.light ? "var(--custom-white)" : "var(--primary-color)"};
  @media screen and (max-width: 768px) {
    font-size: 38px;
  }

  @media screen and (max-width: 480px) {
    font-size: 28;
  }
`;

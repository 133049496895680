import styled from "styled-components";

export const AnnouncementBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--blue-grey);
`;

export const AnnouncementBarContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1100px;
  width: 100%;
  height: 30px;
  padding: 0 20px;
`;

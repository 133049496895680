import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--primary-color);
`;

export const FooterContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  flex-direction: column;
  padding: 24px;
  padding-top: 50px;
`;

export const FooterSectionWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: space-between;
`;

export const FooterLogoWrapper = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 200px;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    padding-right: 50px;
  }
`;

export const FooterLogo = styled.img`
  width: 100px;
  cursor: pointer;
`;

export const FooterLinksWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }
`;

export const FooterLinksColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-self: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    //justify-content: start;
    display: ${(props) => (props.hideOnMobile ? "none" : null)};
  }
`;

export const FooterIconLinkRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const FooterDownloadWrapperMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    padding-top: 10px;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }
`;

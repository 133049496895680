import React from "react";
import InfoSection from "../../components/InfoSection";
import { homeObjTwo } from "../../components/InfoSection/Data";

function AboutPage() {
  return (
    <>
      <InfoSection {...homeObjTwo} />
    </>
  );
}

export default AboutPage;

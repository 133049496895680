import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { GlobalStyle } from "./styles/GlobalStyle";
import MainPage from "./pages/MainPage";
import PrivacyPage from "./pages/PrivacyPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ImprintPage from "./pages/ImprintPage";
import ScrollToTop from "./global_elements/WrapperElements";
import AboutPage from "./pages/AboutPage";
import { GlobalSmallText } from "./global_elements/TextElements";
import AnnouncementBar from "./components/AnnouncementBar";

function App() {
  return (
    <>
      <Router>
        <GlobalStyle />
        <ScrollToTop />
        <AnnouncementBar />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          <Route exact path="/privacy" element={<PrivacyPage />} />
          <Route exact path="/imprint" element={<ImprintPage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;

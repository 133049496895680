import React from "react";
import {
  PrivacyContainer,
  PrivacyContentWrapper,
  PrivacyH1,
  PrivacyP,
} from "./PrivacyElements";

const PrivacySection = () => {
  return (
    <>
      <PrivacyContainer>
        <PrivacyContentWrapper>
          <PrivacyH1>Privacy Policy 🔒</PrivacyH1>
          <PrivacyP>
            This website does not store any data, does not evaluate any data and
            therefore does not pass on any data to third parties. We do not use
            cookies to collect personal data. This website is only used to
            provide information about the Nuddle mobile app as well as a place
            where you can randomly nuddle.
            <br />
            <br /> The mobile app collects 6 data types: <br />
            Coarse Location, Device ID, Product Interaction, Advertising Data,
            Crash Data, Performance Data
            <br />
            <br />
            Our Free app uses AdMob as an ad provider. AdMob wants to show ads
            relevant to you and uses dhe device's advertising ID to serve
            personalized ads based on the user's interests. Read all about the
            Google page to opt-out of customized advertising:
            https://adssettings.google.com/u/0/authenticated.
            <br />
            <br /> And here is the link to Google's Privacy Policies:
            https://policies.google.com/privacy and
            https://policies.google.com/technologies/partner-sites <br />
            <br />
            <br />
            #staysafe
          </PrivacyP>
        </PrivacyContentWrapper>
      </PrivacyContainer>
    </>
  );
};

export default PrivacySection;

import Img1 from "../../assets/img1.png";
import Img2 from "../../assets/img2.png";
import Img3 from "../../assets/img3.png";

export const descriptionData = [
  {
    img: Img1,
    header: "🚀 Always and everywhere",
    description: "Solve the daily riddle or random number sequences",
  },
  {
    img: Img3,
    header: "🧠 Train your brain",
    description: "Find the last 2 numbers of the number sequence",
  },
  {
    img: Img2,
    header: "🔥 Streaks",
    description: "Increase your streak through daily #nuddlin'",
  },
];

import React from "react";
import { descriptionData } from "./Data";
import {
  DescriptionContainer,
  DescriptionH1,
  DescriptionWrapper,
  DescriptionCard,
  DescriptionIcon,
  DescriptionH2,
  DescriptionP,
  DescriptionPrice,
} from "./DescriptionElements";

const DescriptionSection = () => {
  return (
    <>
      <DescriptionContainer id="additionalInformation">
        <DescriptionH1>Solve riddles on the go</DescriptionH1>
        <DescriptionWrapper>
          {descriptionData.map((data, i) => {
            return (
              <>
                <DescriptionCard key={i * 1000}>
                  <DescriptionIcon src={data.img} />
                  <DescriptionH2>{data.header}</DescriptionH2>
                  <DescriptionP>{data.description}</DescriptionP>
                  <DescriptionPrice></DescriptionPrice>
                </DescriptionCard>
              </>
            );
          })}
        </DescriptionWrapper>
      </DescriptionContainer>
    </>
  );
};

export default DescriptionSection;

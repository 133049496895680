import styled from "styled-components";

export const DescriptionContainer = styled.div`
  //height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background: var(--primary-color);

  /*@media screen and (max-width: 1000px) {
    //initial 1100
    height: 1200px;
  }
  @media screen and (max-width: 768px) {
    //initial 1100
    height: 1500px;
  }

  @media screen and (max-width: 480px) {
    //initial 1300
    height: 1600px;
  }*/
`;

export const DescriptionWrapper = styled.div`
  max-width: 1100px;
  // margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const DescriptionCard = styled.div`
  display: flex;
  //background: blue;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  border-radius: 10px;
  max-height: 550px;
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
  padding-bottom: 50px;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
  }
`;

export const DescriptionIcon = styled.img`
  width: 100%;
  heigth: 100%;
  max-width: 400px;
  //aspect-ratio: 2362/2109;
  margin-bottom: 10px;
`;

export const DescriptionH1 = styled.h1`
  font-size: 2.5rem;
  color: var(--custom-white);
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    margin-bottom: 30px;
  }
`;

export const DescriptionH2 = styled.h2`
  font-size: 1rem;
  color: var(--custom-white);
  margin-bottom: 0px;
`;

export const DescriptionP = styled.p`
  font-size: 1rem;
  color: var(--custom-white-opacity);
  // text-align: center;
  margin-bottom: 10px;
`;

export const DescriptionPrice = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 20px;

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

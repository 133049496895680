import HeaderImg from "../../assets/nuddle_header_img.png";
import AboutImg from "../../assets/nuddle_about.png";
import { FaInstagram, FaLinkedin, FaMedium, FaTwitter } from "react-icons/fa";
import { SiLinktree } from "react-icons/si";

export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Get the app",
  headline: "Solve #Nuddles always and everywhere",
  description: "",
  buttonLabel: "",
  imgStart: false,
  img: HeaderImg,
  alt: "mockup of the official nuddle app",
  dark: true,
  showDownload: true,
  primary: true,
};
export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Hi 🤙🏼☕️",
  headline: "About",
  description:
    "Hello, my name is Fabian and I was born in 1998 and I am from Vienna, Austria. In 2019 I started to teach myself programming with the help of YouTube and various information platforms. So far I have published an app called Luminary - Partygame in the AppStore and Google Play Store. Additionally, I am the founder of the online fashion startup called QRSHIBAS, where the technology in the background is also in focus. The idea with Nuddle came to me because I personally love solving puzzles and thought I'd try to provide a simple but comprehensive app. I hope the app serves its purpose and you have fun with it!",
  buttonLabel: "Download",
  imgStart: false,
  img: AboutImg,
  alt: "image about Fabian Weiss holding a coffee",
  dark: true,
  showDownload: false,
  primary: true,
};

export const aboutData = [
  {
    icon: <FaTwitter style={{ height: "25px", width: "25px" }} />,
    href: "https://twitter.com/fabian__weiss",
  },
  {
    icon: <FaInstagram style={{ height: "25px", width: "25px" }} />,
    href: "https://instagram.com/fabian__weiss",
  },
  {
    icon: <FaMedium style={{ height: "25px", width: "25px" }} />,
    href: "https://medium.com/@fabian.weiss",
  },
  {
    icon: <FaLinkedin style={{ height: "25px", width: "25px" }} />,
    href: "https://www.linkedin.com/in/weiss-fabian/",
  },
  {
    icon: <SiLinktree style={{ height: "25px", width: "25px" }} />,
    href: "https://linktr.ee/fabianweiss",
  },
];

// multiply with 2 and add x * (index + 1)
function nuddleOne({ isDaily = false }) {
  var startingIndex = isDaily
    ? 2 + (getDailyBaseNumber() % 10)
    : 2 + getRandomInt(10);
  var multiplier = isDaily
    ? 2 + (getDailyBaseNumber() % 3)
    : 2 + getRandomInt(3);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    if (i === 0 || i % 2 === 0) {
      numberSequence.push(numberSequence[i] * 2 + multiplier * (i + 1));
    } else {
      numberSequence.push(numberSequence[i] + multiplier * (i + 1));
    }
  }
  return numberSequence;
}

// sum of digits of last 2 numbers plus the additionnumber
function nuddleTwo({ isDaily = false }) {
  var startingIndex = isDaily
    ? 2 + (getDailyBaseNumber() % 20)
    : 2 + getRandomInt(20);
  var additionDigit = isDaily
    ? 1 + (getDailyBaseNumber() % 15)
    : 1 + getRandomInt(15);
  var numberSequence = [startingIndex, startingIndex + additionDigit];
  for (var i = 0; i < 6; i++) {
    numberSequence.push(
      sumDigits(numberSequence[i]) +
        sumDigits(numberSequence[i + 1]) +
        additionDigit
    );
  }
  return numberSequence;
}

// sum of the last 2 numbers plus a fixed random value from 0 to 15
function nuddleThree({ isDaily = false }) {
  var startingIndex = isDaily
    ? 1 + (getDailyBaseNumber() % 10)
    : 1 + getRandomInt(10);
  var additionDigit = isDaily ? getDailyBaseNumber() % 10 : getRandomInt(10);
  var numberSequence = [startingIndex, startingIndex + additionDigit];
  for (var i = 0; i < 6; i++) {
    numberSequence.push(
      numberSequence[i] + numberSequence[i + 1] + additionDigit
    );
  }
  return numberSequence;
}

// add the sum of digits of the second number to the first number
function nuddleFour({ isDaily = false }) {
  var startingIndex = isDaily
    ? 12 + (getDailyBaseNumber() % 20)
    : 12 + getRandomInt(20);
  var additionDigit = isDaily
    ? 2 + (getDailyBaseNumber() % 16)
    : 12 + getRandomInt(16);
  var numberSequence = [startingIndex, startingIndex + additionDigit];
  for (var i = 0; i < 6; i++) {
    numberSequence.push(numberSequence[i] + sumDigits(numberSequence[i + 1]));
  }
  return numberSequence;
}

// quadratic sequence
function nuddleFive({ isDaily = false }) {
  var startingIndex = isDaily
    ? 1 + (getDailyBaseNumber() % 20)
    : 1 + getRandomInt(20);
  var floatingAdditionDigit = isDaily
    ? 1 + (getDailyBaseNumber() % 16)
    : 1 + getRandomInt(16);
  var fixedAdditionDigit = isDaily
    ? 1 + (getDailyBaseNumber() % 12)
    : 1 + getRandomInt(11);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    numberSequence.push(
      numberSequence[i] + fixedAdditionDigit * i + floatingAdditionDigit
    );
    floatingAdditionDigit += fixedAdditionDigit * (i + 1);
  }
  return numberSequence;
}

// cubic sequence
function nuddleSix({ isDaily = false }) {
  var startingIndex = isDaily
    ? 1 + (getDailyBaseNumber() % 20)
    : 1 + getRandomInt(20);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    numberSequence.push(Math.pow(i + 1, 3) + startingIndex);
  }
  return numberSequence;
}

// next prime number and add random value from 1 to 20
function nuddleSeven({ isDaily = false }) {
  var startingNumber = isDaily
    ? 1 + (getDailyBaseNumber() % 50)
    : 1 + getRandomInt(50);
  var numberSequence = [nextPrime(startingNumber)];
  for (var i = 0; i < 7; i++) {
    numberSequence.push(nextPrime(numberSequence[i]) + startingNumber);
  }
  return numberSequence;
}

// add all numbers of list together and add index * random number between 1 and 10
function nuddleEight({ isDaily = false }) {
  var startingIndex = isDaily
    ? 1 + (getDailyBaseNumber() % 4)
    : 1 + getRandomInt(4);
  var additionalNumber = isDaily
    ? 1 + (getDailyBaseNumber() % 4)
    : 1 + getRandomInt(4);
  var numberSequence = [startingIndex, startingIndex + additionalNumber];
  for (var i = 0; i < 6; i++) {
    numberSequence.push(
      getArraySum(numberSequence) + additionalNumber * (i + 2)
    );
  }
  return numberSequence;
}

// bigger number - smaller number of last 2 numbers and adding the last 2 numbers alternately (starting with subtraction)
function nuddleNine({ isDaily = false }) {
  var startingIndex = isDaily
    ? 1 + (getDailyBaseNumber() % 9)
    : 1 + getRandomInt(9);
  var additionalNumber = isDaily
    ? 10 + (getDailyBaseNumber() % 9)
    : 10 + getRandomInt(9);
  var numberSequence = [startingIndex, startingIndex + additionalNumber];
  for (var i = 0; i < 6; i++) {
    if (i === 0 || i % 2 === 0) {
      var bigger = Math.max(numberSequence[i], numberSequence[i + 1]);
      var smaller = Math.min(numberSequence[i], numberSequence[i + 1]);
      numberSequence.push(bigger - smaller);
    } else {
      numberSequence.push(numberSequence[i] + numberSequence[i + 1]);
    }
  }
  return numberSequence;
}

// add modulo of number before plus a random number between 1 and 20
function nuddleTen({ isDaily = false }) {
  var startingIndex = isDaily
    ? 50 + (getDailyBaseNumber() % 40)
    : 50 + getRandomInt(40);
  var moduloNumber = isDaily
    ? 3 + (getDailyBaseNumber() % 7)
    : 3 + getRandomInt(7);
  var additionalNumber = isDaily
    ? 1 + (getDailyBaseNumber() % 20)
    : 1 + getRandomInt(20);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    numberSequence.push(
      numberSequence[i] + (numberSequence[i] % moduloNumber) + additionalNumber
    );
  }
  return numberSequence;
}

// subtract x and multiply by y (System 1)
function nuddleEleven({ isDaily = false }) {
  var startingIndex = isDaily
    ? 20 + (getDailyBaseNumber() % 16)
    : 20 + getRandomInt(16);
  var subtractor = isDaily
    ? 1 + (getDailyBaseNumber() % 15)
    : 1 + getRandomInt(10);
  var multiplier = isDaily
    ? 2 + (getDailyBaseNumber() % 3)
    : 2 + getRandomInt(2);
  var numberSequence = [startingIndex, startingIndex - subtractor];
  for (var i = 0; i < 6; i++) {
    if (i === 0 || i % 2 === 0) {
      numberSequence.push(numberSequence[i + 1] * multiplier);
    } else {
      numberSequence.push(numberSequence[i + 1] - subtractor);
    }
  }
  return numberSequence;
}

// add x plus y accumulated and subtract z minus i alternately (System 2)
function nuddleTwelve({ isDaily = false }) {
  var startingIndex = isDaily
    ? 15 + (getDailyBaseNumber() % 35)
    : 15 + getRandomInt(36);
  var subtractor = isDaily
    ? 1 + (getDailyBaseNumber() % 15)
    : 1 + getRandomInt(10);
  var additionNumber = isDaily
    ? 2 + (getDailyBaseNumber() % 15)
    : 4 + getRandomInt(15);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    if (i === 0 || i % 2 === 0) {
      numberSequence.push(numberSequence[i] + additionNumber);
      additionNumber += additionNumber;
    } else {
      numberSequence.push(numberSequence[i] - subtractor);
      subtractor += 1;
    }
  }
  return numberSequence;
}

// start with an incrementer and increment the incrementer based on index (System 3)
function nuddleThirteen({ isDaily = false }) {
  var startingIndex = isDaily
    ? 20 + (getDailyBaseNumber() % 40)
    : 20 + getRandomInt(40);
  var multiplier = 2;
  var additionNumberOuter = isDaily
    ? 1 + (getDailyBaseNumber() % 3)
    : 1 + getRandomInt(3);
  var additionNumberInner = isDaily
    ? 1 + (getDailyBaseNumber() % 15)
    : 1 + getRandomInt(15);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    numberSequence.push(
      numberSequence[i] + additionNumberInner + additionNumberOuter * multiplier
    );
    additionNumberOuter = additionNumberOuter * multiplier;
  }
  return numberSequence;
}

// start with an even number, divide by random number, multiply by a random number, add an even number (System 4)
function nuddleFourteen({ isDaily = false }) {
  var startingIndex = roundToEven(
    isDaily ? 20 + (getDailyBaseNumber() % 21) : 20 + getRandomInt(21)
  );
  var multiplier = isDaily
    ? 4 + (getDailyBaseNumber() % 3)
    : 4 + getRandomInt(3);
  var additionNumber = roundToEven(
    isDaily ? 10 + (getDailyBaseNumber() % 25) : 10 + getRandomInt(25)
  );
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    if (i === 0 || i % 3 === 0) {
      numberSequence.push(
        Math.trunc(numberSequence[i] / roundToEven(multiplier - 2))
      );
    } else if (i === 1 || i % 4 === 0) {
      numberSequence.push(numberSequence[i] * multiplier);
    } else if (i === 2 || i % 5 === 0) {
      numberSequence.push(numberSequence[i] + additionNumber);
    }
  }
  return numberSequence;
}

// increment by x + (i + 1), decrement by y - (i + 1), multiply by 2 and 3 (System 5)
function nuddleFifteen({ isDaily = false }) {
  var startingIndex = isDaily
    ? 20 + (getDailyBaseNumber() % 40)
    : 20 + getRandomInt(40);
  var subtractor = isDaily
    ? 6 + (getDailyBaseNumber() % 10)
    : 6 + getRandomInt(10);
  var additionNumber = isDaily
    ? 10 + (getDailyBaseNumber() % 12)
    : 10 + getRandomInt(12);
  var incrementorAndDecrementor = isDaily
    ? 1 + (getDailyBaseNumber() % 3)
    : 1 + getRandomInt(3);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    if (i === 0 || i % 3 === 0) {
      numberSequence.push(numberSequence[i] + additionNumber);
      additionNumber += incrementorAndDecrementor;
    } else if (i === 1 || i % 4 === 0) {
      numberSequence.push(numberSequence[i] - subtractor);
      subtractor = subtractor - incrementorAndDecrementor;
    } else if (i === 2 || i % 5 === 0) {
      numberSequence.push(numberSequence[i] * (2 + (i % 2)));
    }
  }
  return numberSequence;
}

// Fibonacci first add a number than subtract the other (System 7)
function nuddleSixteen({ isDaily = false }) {
  var startingIndex = isDaily
    ? 20 + (getDailyBaseNumber() % 40)
    : 20 + getRandomInt(40);
  var startingIndex2 = isDaily
    ? 5 + (getDailyBaseNumber() % 15)
    : 5 + getRandomInt(15);
  var numberSequence = [
    startingIndex,
    startingIndex2,
    startingIndex + startingIndex2,
  ];
  for (var i = 0; i < 5; i++) {
    if (i === 0 || i % 2 === 0) {
      numberSequence.push(
        numberSequence[numberSequence.length - 1] -
          numberSequence[numberSequence.length - 2]
      );
      if (numberSequence.length < 8) {
        numberSequence.push(
          numberSequence[numberSequence.length - 1] +
            numberSequence[numberSequence.length - 2]
        );
      }
    }
  }
  return numberSequence;
}

// add the 2 numbers of the nearest even indexes and odd indexes together (System 9)
function nuddleSeventeen({ isDaily = false }) {
  var startingIndex = isDaily
    ? 1 + (getDailyBaseNumber() % 40)
    : 1 + getRandomInt(40);
  var startingIndex2 = isDaily
    ? 1 + (getDailyBaseNumber() % 40)
    : 1 + getRandomInt(40);
  var startingIndex3 = isDaily
    ? 1 + (getDailyBaseNumber() % 40)
    : 1 + getRandomInt(40);
  var numberSequence = [startingIndex, startingIndex2, startingIndex3];
  for (var i = 0; i < 5; i++) {
    numberSequence.push(numberSequence[i] + numberSequence[i + 2]);
  }
  return numberSequence;
}

// add a constant x and multiply by factor y based on penultimate number (System 11)
function nuddleEighteen({ isDaily = false }) {
  var startingIndex = isDaily
    ? 1 + (getDailyBaseNumber() % 10)
    : 1 + getRandomInt(10);
  var multiplier = isDaily
    ? 2 + (getDailyBaseNumber() % 2)
    : 2 + getRandomInt(2);
  var additionalNumber = isDaily
    ? 1 + (getDailyBaseNumber() % 15)
    : 1 + getRandomInt(15);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    if (i === 0 || i % 2 === 0) {
      numberSequence.push(numberSequence[i] + additionalNumber);
    } else {
      numberSequence.push(numberSequence[i] * multiplier);
    }
  }
  return numberSequence;
}

// add an accumulated x and subtract a constant y on penultimate numbers (System 12)
function nuddleNineteen({ isDaily = false }) {
  var startingIndex = isDaily
    ? 10 + (getDailyBaseNumber() % 40)
    : 10 + getRandomInt(40);
  var startingIndex2 = isDaily
    ? 50 + (getDailyBaseNumber() % 15)
    : 50 + getRandomInt(15);
  var subtractor = isDaily
    ? 2 + (getDailyBaseNumber() % 7)
    : 2 + getRandomInt(7);
  var additionalNumber = isDaily
    ? 5 + (getDailyBaseNumber() % 25)
    : 5 + getRandomInt(25);
  var numberSequence = [startingIndex, startingIndex2];
  for (var i = 0; i < 6; i++) {
    if (i === 0 || i % 2 === 0) {
      numberSequence.push(numberSequence[i] + additionalNumber);
      additionalNumber += additionalNumber;
    } else {
      numberSequence.push(numberSequence[i] - subtractor);
    }
  }
  return numberSequence;
}

// multiply index value with index + 1 starting with 3 values (System 14)
function nuddleTwenty({ isDaily = false }) {
  var startingIndex = isDaily
    ? 5 + (getDailyBaseNumber() % 40)
    : 5 + getRandomInt(40);
  var startingIndex2 = isDaily
    ? 5 + (getDailyBaseNumber() % 40)
    : 5 + getRandomInt(40);
  var startingIndex3 = isDaily
    ? 5 + (getDailyBaseNumber() % 40)
    : 5 + getRandomInt(40);
  var numberSequence = [startingIndex, startingIndex2, startingIndex3];
  for (var i = 0; i < 5; i++) {
    numberSequence.push(numberSequence[i] * (i + 1));
  }
  return numberSequence;
}

// start with 2 numbers and add x. Increment x = 2x on each loop (System 15)
function nuddleTwentyOne({ isDaily = false }) {
  var startingIndex = isDaily
    ? 5 + (getDailyBaseNumber() % 40)
    : 5 + getRandomInt(40);
  var startingIndex2 = isDaily
    ? 5 + (getDailyBaseNumber() % 40)
    : 5 + getRandomInt(40);
  var additionalNumber = isDaily
    ? 2 + (getDailyBaseNumber() % 10)
    : 2 + getRandomInt(10);
  var numberSequence = [startingIndex, startingIndex2];
  for (var i = 0; i < 6; i++) {
    numberSequence.push(numberSequence[i] + additionalNumber);
    additionalNumber = additionalNumber * 2;
  }
  return numberSequence;
}

// add the sum digits of a number to get the next number (System 17)
function nuddleTwentyTwo({ isDaily = false }) {
  var startingIndex = isDaily
    ? 10 + (getDailyBaseNumber() % 40)
    : 10 + getRandomInt(40);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    numberSequence.push(numberSequence[i] + sumDigits(numberSequence[i]));
  }
  return numberSequence;
}

// add number 1 and 3, multiply number index 2 by x and so on (System 18)
function nuddleTwentyThree({ isDaily = false }) {
  var startingIndex = isDaily
    ? 5 + (getDailyBaseNumber() % 31)
    : 5 + getRandomInt(31);
  var startingIndex2 = isDaily
    ? 5 + (getDailyBaseNumber() % 31)
    : 5 + getRandomInt(31);
  var multiplier = isDaily
    ? 2 + (getDailyBaseNumber() % 2)
    : 2 + getRandomInt(2);
  var numberSequence = [startingIndex, startingIndex2];
  for (var i = 0; i < 6; i++) {
    if (i === 0 || i % 2 === 0) {
      numberSequence.push(numberSequence[i] + numberSequence[i + 1]);
    } else {
      numberSequence.push(numberSequence[i] * multiplier);
    }
  }
  return numberSequence;
}

// add sum digits on even index and preultimate fibonacci on odd index (System 19)
function nuddleTwentyFour({ isDaily = false }) {
  var startingIndex = isDaily
    ? 5 + (getDailyBaseNumber() % 40)
    : 5 + getRandomInt(40);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    if (i === 0 || i % 2 === 0) {
      numberSequence.push(numberSequence[i] + sumDigits(numberSequence[i]));
      if (numberSequence.length < 8) {
        numberSequence.push(numberSequence[i] + numberSequence[i + 1]);
      }
    }
  }
  return numberSequence;
}

// add number x to first number, add this generated number to create third number. multiply third number by y (System 20)
function nuddleTwentyFive({ isDaily = false }) {
  var startingIndex = isDaily
    ? 5 + (getDailyBaseNumber() % 20)
    : 5 + getRandomInt(20);
  var multiplier = 2;
  var additionalNumber = isDaily
    ? 4 + (getDailyBaseNumber() % 7)
    : 4 + getRandomInt(7);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    if (i === 0 || i % 3 === 0) {
      numberSequence.push(numberSequence[i] + additionalNumber);
      if (numberSequence.length < 8) {
        numberSequence.push(numberSequence[i] + numberSequence[i + 1]);
      }
    } else if (i === 2 || i === 5 || i === 8) {
      numberSequence.push(numberSequence[i] * multiplier);
    }
  }
  return numberSequence;
}

// Fibonacci of 4 numbers (System 24)
function nuddleTwentySix({ isDaily = false }) {
  var startingIndex = isDaily
    ? 2 + (getDailyBaseNumber() % 20)
    : 2 + getRandomInt(20);
  var startingIndex2 = isDaily
    ? startingIndex + (getDailyBaseNumber() % 8)
    : startingIndex + getRandomInt(8);
  var startingIndex3 = isDaily
    ? startingIndex2 + (getDailyBaseNumber() % 7)
    : startingIndex2 + getRandomInt(7);
  var startingIndex4 = isDaily
    ? startingIndex3 + (getDailyBaseNumber() % 7)
    : startingIndex3 + getRandomInt(7);
  var numberSequence = [
    startingIndex,
    startingIndex2,
    startingIndex3,
    startingIndex4,
  ];
  for (var i = 0; i < 4; i++) {
    numberSequence.push(
      numberSequence[i] +
        numberSequence[i + 1] +
        numberSequence[i + 2] +
        numberSequence[i + 3]
    );
  }
  return numberSequence;
}

// Fibonacci first number plus third number is fifth number (System 26)
function nuddleTwentySeven({ isDaily = false }) {
  var startingIndex = isDaily
    ? 2 + (getDailyBaseNumber() % 20)
    : 2 + getRandomInt(20);
  var startingIndex2 = isDaily
    ? startingIndex + (getDailyBaseNumber() % 20)
    : startingIndex + getRandomInt(20);
  var startingIndex3 = isDaily
    ? startingIndex2 + (getDailyBaseNumber() % 20)
    : startingIndex2 + getRandomInt(20);
  var startingIndex4 = isDaily
    ? startingIndex3 + (getDailyBaseNumber() % 20)
    : startingIndex3 + getRandomInt(20);
  var numberSequence = [
    startingIndex,
    startingIndex2,
    startingIndex3,
    startingIndex4,
  ];
  for (var i = 0; i < 4; i++) {
    numberSequence.push(
      numberSequence[i] + numberSequence[numberSequence.length - 2]
    );
  }
  return numberSequence;
}

// Change of the positivity at each loop (System 27)
function nuddleTwentyEight({ isDaily = false }) {
  var startingIndex = isDaily
    ? 40 + (getDailyBaseNumber() % 100)
    : 40 + getRandomInt(100);
  var deOrIncrementor = isDaily
    ? 1 + (getDailyBaseNumber() % 12)
    : 1 + getRandomInt(12);
  var jumpNumber = isDaily
    ? 2 + (getDailyBaseNumber() % 7)
    : 2 + getRandomInt(7);
  var numberSequence = [startingIndex];
  for (var i = 0; i < 7; i++) {
    if (i === 0 || i % 2 === 0) {
      numberSequence.push(numberSequence[i] - deOrIncrementor);
      deOrIncrementor += jumpNumber;
    } else {
      numberSequence.push(numberSequence[i] + deOrIncrementor);
      deOrIncrementor += jumpNumber;
    }
  }
  return numberSequence;
}

// add constant number on odd index, add triple Fibonacci on even index (System 22)
function nuddleTwentyNine({ isDaily = false }) {
  var startingIndex = isDaily
    ? 5 + (getDailyBaseNumber() % 10)
    : 5 + getRandomInt(10);
  var startingIndex3 = isDaily
    ? 17 + (getDailyBaseNumber() % 20)
    : 17 + getRandomInt(20);
  var additionalNumber = isDaily
    ? 2 + (getDailyBaseNumber() % 12)
    : 2 + getRandomInt(12);
  var numberSequence = [
    startingIndex,
    startingIndex + additionalNumber,
    startingIndex3 + additionalNumber,
  ];
  for (var i = 0; i < 5; i++) {
    if (i === 0 || i % 2 === 0) {
      numberSequence.push(
        numberSequence[numberSequence.length - 1] +
          numberSequence[numberSequence.length - 2] +
          numberSequence[numberSequence.length - 3]
      );
    } else {
      numberSequence.push(
        numberSequence[numberSequence.length - 1] + additionalNumber
      );
    }
  }
  numberSequence.sort();
  return numberSequence;
}

// Fibonacci of all numbers plus additional number
function nuddleThirty({ isDaily = false }) {
  var startingIndex = isDaily
    ? 2 + (getDailyBaseNumber() % 4)
    : 2 + getRandomInt(4);
  //var startingIndex2 = startingIndex + getRandomInt(11);
  var additionalNumber = isDaily
    ? 1 + (getDailyBaseNumber() % 4)
    : 1 + getRandomInt(4);
  var numberSequence = [startingIndex, startingIndex + additionalNumber];
  for (var i = 0; i < 6; i++) {
    numberSequence.push(getArraySum(numberSequence) + additionalNumber);
  }
  return numberSequence;
}

// helper functions
function getArraySum(a) {
  return a.reduce(function (pv, cv) {
    return pv + cv;
  }, 0);
}

function sumDigits(num) {
  return String(num)
    .split("")
    .reduce((acc, curr) => {
      return acc + Number(curr);
    }, 0);
}

// check if it is a prime number
function isPrime(N) {
  for (var i = 2; i <= N / i; ++i) {
    if (N % i === 0) {
      return false;
    }
  }
  return true;
}

// get next prime number
function nextPrime(num) {
  while (!isPrime(++num)) {}
  return num;
}

// round to even number
function roundToEven(n) {
  if (n % 2 === 0) {
    return n;
  } else {
    return n + 1;
  }
}

function getDailyBaseNumber() {
  return 0;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function getNuddle() {
  const schemaIndex = 1 + getRandomInt(30);
  console.log(`number sequence is based on ${schemaIndex}`);
  switch (schemaIndex) {
    case 1:
      return nuddleOne(false);
    case 2:
      return nuddleTwo(false);
    case 3:
      return nuddleThree(false);
    case 4:
      return nuddleFour(false);
    case 5:
      return nuddleFive(false);
    case 6:
      return nuddleSix(false);
    case 7:
      return nuddleSeven(false);
    case 8:
      return nuddleEight(false);
    case 9:
      return nuddleNine(false);
    case 10:
      return nuddleTen(false);
    case 11:
      return nuddleEleven(false);
    case 12:
      return nuddleTwelve(false);
    case 13:
      return nuddleThirteen(false);
    case 14:
      return nuddleFourteen(false);
    case 15:
      return nuddleFifteen(false);
    case 16:
      return nuddleSixteen(false);
    case 17:
      return nuddleSeventeen(false);
    case 18:
      return nuddleEighteen(false);
    case 19:
      return nuddleNineteen(false);
    case 20:
      return nuddleTwenty(false);
    case 21:
      return nuddleTwentyOne(false);
    case 22:
      return nuddleTwentyTwo(false);
    case 23:
      return nuddleTwentyThree(false);
    case 24:
      return nuddleTwentyFour(false);
    case 25:
      return nuddleTwentyFive(false);
    case 26:
      return nuddleTwentySix(false);
    case 27:
      return nuddleTwentySeven(false);
    case 28:
      return nuddleTwentyEight(false);
    case 29:
      return nuddleTwentyNine(false);
    case 30:
      return nuddleThirty(false);
    default:
      return nuddleOne(false);
  }
}

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

#root {
  --custom-black: #2b2b2b;
  --custom-white: #ffffff;
  --custom-white-opacity: #ffffffA6;
  --custom-white-opacity-2: #ffffff1A;
  --creme-white: #f9f9f9;
  --custom-grey: #9E9E9E;

  --primary-color: #2f3152; // dark blue
  --primary-color-opacity: #2f315233; // dark blue with opacity
  --secondary-color: #1f213d; // darker blue

  --blue-grey: #D9E6F9;
  

}

*, *::before, *::after {
  box-sizing: inherit;
}

body.preload *{ animation-duration: 0s !important; -webkit-animation-duration: 0s !important; transition:background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;} 

html, body {
    overflow-x: hidden;
    background: #f9f9f9;
    margin: 0;
    font-family: "Inter", sans-serif;
    padding: 0;
  }
`;

import React, { useEffect, useState } from "react";
import { getNuddle } from "./NuddleFunctions";
import { FiDelete } from "react-icons/fi";
import {
  NuddleContainer,
  NuddleContentWrapper,
  NuddleSequenceGrid,
  NuddleNumberWrapper,
  NuddleNumber,
  NuddleKeyboardGrid,
  KeyboardButton,
  EnterButton,
  DeleteButton,
  ScrollIcon,
  SpyWrapper,
  EyeWrapper,
  EyeImg,
  SpyImage,
} from "./NuddleElements";
import { VerticalSpace } from "../../global_elements/SpacerElements";
import { GlobalSmallText } from "../../global_elements/TextElements";
import MrNud from "../../assets/mrnud.png";
import Eye from "../../assets/eye.png";

function NuddleSection() {
  const keyboardNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
  const [numberSequence, setNumberSequence] = useState([]);
  const [numberOne, setNumberOne] = useState("");
  const [numberTwo, setNumberTwo] = useState("");
  const [currentNumberIndex, setCurrentNumberIndex] = useState(1);
  const [isFinished, setIsFinished] = useState(false);
  const [toggleShake, setToggleShake] = useState(false);

  function nextNuddle() {
    setNumberOne("");
    setNumberTwo("");
    setCurrentNumberIndex(1);
    setIsFinished(false);
    setNumberSequence(getNuddle());
  }

  function decrementNumber() {
    if (currentNumberIndex === 1) {
      if (numberOne.length === 0) {
        return;
      } else {
        setNumberOne(numberOne.slice(0, -1));
      }
    } else {
      if (numberTwo.length === 0) {
        return;
      } else {
        setNumberTwo(numberTwo.slice(0, -1));
      }
    }
  }

  function incrementNumber(n) {
    if (currentNumberIndex === 1) {
      if (numberOne.length === 3) {
        return;
      } else {
        setNumberOne(numberOne + n);
      }
    } else {
      if (numberTwo.length === 3) {
        return;
      } else {
        setNumberTwo(numberTwo + n);
      }
    }
  }

  function enterPressed() {
    console.log("enter pressed");
    if (currentNumberIndex === 1 && !isFinished) {
      if (numberOne === numberSequence[6].toString()) {
        console.log("Right number!");
        setCurrentNumberIndex(2);
      } else {
        setToggleShake(!toggleShake);
        console.log(`false number! The correct numbers are ${numberSequence}`);
      }
    } else if (currentNumberIndex === 2 && !isFinished) {
      if (numberTwo === numberSequence[7].toString()) {
        console.log("Right second number! You completed the riddle");
        setIsFinished(true);
      } else {
        setToggleShake(!toggleShake);
        console.log(`false number! The correct numbers are ${numberSequence}`);
      }
    } else if (isFinished) {
      console.log("Finished! Generating next Nuddle");
      nextNuddle();
    } else {
      setToggleShake(!toggleShake);
      console.log(`false number! The correct numbers are ${numberSequence}`);
    }
  }

  useEffect(() => {
    console.log("fire use effect");
    setNumberSequence(getNuddle());
    setTimeout(function () {
      document.body.className = "";
    }, 1000);
    document.addEventListener("mousemove", (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      const eyes = document.querySelectorAll(".eye");
      const anchor = document.getElementById("anchor");
      const rekt = anchor.getBoundingClientRect();
      const anchorX = rekt.left + rekt.width / 2;
      const anchorY = rekt.top + rekt.height / 2;
      const angleDeg = angle(mouseX, mouseY, anchorX, anchorY);

      eyes.forEach((eye) => {
        eye.style.transform = `rotate(${90 + angleDeg}deg)`;
        // anchor.style.filter = `hue-rotate(${angleDeg * 100}deg)`;
      });
    });
  }, []);

  function angle(cx, cy, ex, ey) {
    const dy = ey - cy;
    const dx = ex - cx;
    const rad = Math.atan2(dy, dx);
    const deg = (rad * 180) / Math.PI;
    return deg;
  }

  return (
    <>
      <NuddleContainer>
        <NuddleContentWrapper>
          <NuddleSequenceGrid>
            {numberSequence.slice(0, 6).map((n, i) => {
              return (
                <>
                  <NuddleNumberWrapper key={i}>
                    <NuddleNumber>{n}</NuddleNumber>
                  </NuddleNumberWrapper>
                </>
              );
            })}
            <NuddleNumberWrapper
              toggle={
                toggleShake === true && currentNumberIndex === 1 && !isFinished
              }
              active={currentNumberIndex === 1}
            >
              <NuddleNumber>{numberOne}</NuddleNumber>
            </NuddleNumberWrapper>
            <NuddleNumberWrapper
              toggle={toggleShake && currentNumberIndex === 2 && !isFinished}
              active={currentNumberIndex === 2 && !isFinished}
            >
              <NuddleNumber>{numberTwo}</NuddleNumber>
            </NuddleNumberWrapper>
          </NuddleSequenceGrid>
          <NuddleKeyboardGrid>
            {keyboardNumbers.map((n, i) => {
              return (
                <>
                  <KeyboardButton
                    key={i * 100}
                    onClick={() => incrementNumber(n)}
                  >
                    {n}
                  </KeyboardButton>
                </>
              );
            })}
            <EnterButton onClick={() => enterPressed()}>
              {isFinished ? "Next Nuddle" : "Enter"}
            </EnterButton>
            <DeleteButton onClick={() => decrementNumber()}>
              <FiDelete />
            </DeleteButton>
          </NuddleKeyboardGrid>
          <VerticalSpace vs={"40px"} />
          <SpyWrapper>
            <SpyImage id="anchor" src={MrNud} />
            <EyeWrapper>
              <EyeImg
                className={"eye"}
                src={Eye}
                style={{ top: "35px", left: "4px" }}
              />
              <EyeImg
                className={"eye"}
                src={Eye}
                style={{ top: "35px", right: "4px" }}
              />
            </EyeWrapper>
          </SpyWrapper>

          <VerticalSpace vs={"40px"} />
          <ScrollIcon />
        </NuddleContentWrapper>
      </NuddleContainer>
    </>
  );
}

export default NuddleSection;

import React from "react";
import {
  ImprintContainer,
  ImprintContentWrapper,
  ImprintH1,
  ImprintP,
} from "./ImprintElements";

function ImprintSection() {
  return (
    <>
      <ImprintContainer>
        <ImprintContentWrapper>
          <ImprintH1>Imprint 📄</ImprintH1>
          <ImprintP>
            Information obligation according to §5 E-Commerce Act, §14 Company
            Code, §63 Industrial Code and disclosure obligation according to
            §Media Act.
            <br />
            <br />
            nuddle.
            <br />
            Fabian Weiss
            <br />
            Pülslgasse 22/10
            <br />
            1230 Vienna
            <br />
            AUSTRIA
            <br />
            <br />
            Trade wording: Commercial business with the exception of regulated
            commercial business and commercial agent
            <br />
            E-mail: nuddle.quiz@gmail.com
            <br />
            Professional law: Trade regulations: www.ris.bka.gv.at
            <br />
            <br />
            #legalthings
          </ImprintP>
        </ImprintContentWrapper>
      </ImprintContainer>
    </>
  );
}

export default ImprintSection;

import React from "react";
import NuddleLogoBlack from "../../assets/nuddle_logo_light.svg";
import {
  NavbarContainer,
  NavbarContentWrapper,
  NavbarLogoWrapper,
  NavbarLogo,
} from "./NavbarElements";

function Navbar() {
  return (
    <>
      <NavbarContainer>
        <NavbarContentWrapper>
          <NavbarLogoWrapper to={"/"}>
            <NavbarLogo src={NuddleLogoBlack} />
          </NavbarLogoWrapper>
        </NavbarContentWrapper>
      </NavbarContainer>
    </>
  );
}

export default Navbar;

import React from "react";
import CTASection from "../../components/CTASection";
import DescriptionSection from "../../components/DescriptionSection";
import Footer from "../../components/Footer";
import InfoSection from "../../components/InfoSection";
import { homeObjOne } from "../../components/InfoSection/Data";
import Navbar from "../../components/Navbar";
import NuddleSection from "../../components/NuddleSection";

function MainPage() {
  return (
    <>
      <NuddleSection />
      <InfoSection {...homeObjOne} />
      <DescriptionSection />
      <CTASection />
    </>
  );
}

export default MainPage;

import React from "react";
import ImprintSection from "../../components/ImprintSection";

function ImprintPage() {
  return (
    <>
      <ImprintSection />
    </>
  );
}

export default ImprintPage;

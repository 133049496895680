import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NavbarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
`;

export const NavbarContentWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
`;

// we just need this if we want to make it clickable in the future
export const NavbarLogoWrapper = styled(NavLink)``;

export const NavbarLogo = styled.img`
  width: 100px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    /* filter: invert(11%) sepia(7%) saturate(6126%) hue-rotate(2deg)
      brightness(100%) contrast(92%); */
  }
`;

import React from "react";
import PrivacySection from "../../components/PrivacySection";

function PrivacyPage() {
  return (
    <>
      <PrivacySection />
    </>
  );
}

export default PrivacyPage;

import styled from "styled-components";

export const CTAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--creme-white);
`;

export const CTAContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
  min-height: 500px;
  max-width: 1100px;
`;

export const CTABox = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  background: var(--blue-grey);
  width: 100%;
  border-radius: 30px;
  padding: 20px 40px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const CTAImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const CTAImg = styled.img`
  width: 75%;
  height: 75%;
`;

export const CTAText = styled.h2`
  color: var(--primary-color);
  font-size: 30px;

  @media screen and (max-width: 900px) {
    font-size: 24px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const CTAButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
`;

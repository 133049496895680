import React from "react";
import CTAImage from "../../assets/nuddle_footer_img.svg";
import {
  GlobalDownloadBadge,
  GlobalDownloadWrapper,
} from "../../global_elements/DownloadElements";
import AppleBadge from "../../assets/apple_badge_black.svg";
import GooglePlayBadge from "../../assets/google_play_badge.png";
import {
  CTAContainer,
  CTAContentWrapper,
  CTABox,
  CTAImageWrapper,
  CTAImg,
  CTAButtonRow,
  CTAText,
} from "./CTAElements";
import { GlobalTextLinkExternal } from "../../global_elements/LinkElements";

function CTASection() {
  return (
    <>
      <CTAContainer>
        <CTAContentWrapper>
          <CTABox>
            <CTAImageWrapper>
              <CTAImg src={CTAImage}></CTAImg>
            </CTAImageWrapper>
            <CTAText>Start nuddlin' today and download our mobile app</CTAText>
            <CTAButtonRow>
              <GlobalTextLinkExternal
                href={
                  "https://apps.apple.com/app/nuddle-number-sequences/id1643422440"
                }
                target={"_blank"}
              >
                <GlobalDownloadWrapper>
                  <GlobalDownloadBadge src={AppleBadge} />
                </GlobalDownloadWrapper>
              </GlobalTextLinkExternal>

              <GlobalTextLinkExternal
                href={
                  "https://play.google.com/store/apps/details?id=com.weiss.nuddle"
                }
                target={"_blank"}
              >
                <GlobalDownloadWrapper>
                  <GlobalDownloadBadge src={GooglePlayBadge} />
                </GlobalDownloadWrapper>
              </GlobalTextLinkExternal>
            </CTAButtonRow>
          </CTABox>
        </CTAContentWrapper>
      </CTAContainer>
    </>
  );
}

export default CTASection;

import styled from "styled-components";

export const ImprintContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ImprintContentWrapper = styled.div`
  padding: 100px 40px;
  max-width: 400px;
  min-height: 600px;
`;

export const ImprintH1 = styled.h1`
  font-weight: bold;
  color: var(--primary-color);
`;

export const ImprintP = styled.p`
  font-size: 14;
  color: var(--primary-color);
`;

import styled from "styled-components";

export const PrivacyContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const PrivacyContentWrapper = styled.div`
  padding: 100px 40px;
  max-width: 400px;
  min-height: 600px;
`;

export const PrivacyH1 = styled.h1`
  font-weight: bold;
  color: var(--primary-color);
`;

export const PrivacyP = styled.p`
  font-size: 14;
  color: var(--primary-color);
`;

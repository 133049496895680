import React from "react";
import { GlobalSmallText } from "../../global_elements/TextElements";
import {
  AnnouncementBarContainer,
  AnnouncementBarContentWrapper,
} from "./AnnouncementBarElements";

function AnnouncementBar() {
  return (
    <>
      <AnnouncementBarContainer>
        <AnnouncementBarContentWrapper>
          <GlobalSmallText center={"true"}>
            find the last 2️⃣ numbers and #justnuddle
          </GlobalSmallText>
        </AnnouncementBarContentWrapper>
      </AnnouncementBarContainer>
    </>
  );
}

export default AnnouncementBar;

import React from "react";
import LogoBlack from "../../assets/nuddle_logo_light.svg";
import {
  GlobalDownloadBadge,
  GlobalDownloadWrapper,
} from "../../global_elements/DownloadElements";
import {
  GlobalTextLinkExternal,
  GlobalTextLinkInternal,
} from "../../global_elements/LinkElements";
import {
  Divider,
  HorizontalSpace,
  VerticalSpace,
} from "../../global_elements/SpacerElements";
import {
  GlobalSmallText,
  GlobalSubtitleSmall,
} from "../../global_elements/TextElements";
import {
  footerDataCompany,
  footerDataDownload,
  footerDataFollowUs,
} from "./Data";
import {
  FooterIconLinkRow,
  FooterLinksColumn,
  FooterLinksWrapper,
  FooterContainer,
  FooterLogoWrapper,
  FooterContentWrapper,
  FooterSectionWrapper,
  FooterLogo,
  FooterDownloadWrapperMobile,
} from "./FooterElements";

function Footer() {
  return (
    <>
      <FooterContainer>
        <FooterContentWrapper>
          <FooterSectionWrapper>
            <FooterLogoWrapper to={"/"}>
              <FooterLogo src={LogoBlack} />
            </FooterLogoWrapper>
            <FooterLinksWrapper>
              <FooterLinksColumn>
                <GlobalSubtitleSmall light>
                  {footerDataCompany.header}
                </GlobalSubtitleSmall>
                {footerDataCompany.links.map((link, i) => {
                  return (
                    <>
                      <GlobalTextLinkInternal light key={i - 100} to={link.to}>
                        {link.label}
                      </GlobalTextLinkInternal>
                    </>
                  );
                })}
              </FooterLinksColumn>
              <FooterLinksColumn>
                <GlobalSubtitleSmall light>
                  {footerDataFollowUs.header}
                </GlobalSubtitleSmall>
                {footerDataFollowUs.links.map((link, i) => {
                  return (
                    <>
                      <FooterIconLinkRow>
                        {link.icon}
                        <HorizontalSpace hs={"7.5px"} />
                        <GlobalTextLinkExternal
                          light
                          href={link.to}
                          target={"_blank"}
                        >
                          {link.label}
                        </GlobalTextLinkExternal>
                      </FooterIconLinkRow>
                    </>
                  );
                })}
              </FooterLinksColumn>
              <FooterLinksColumn hideOnMobile>
                <GlobalSubtitleSmall light>
                  {footerDataDownload.header}
                </GlobalSubtitleSmall>
                {footerDataDownload.links.map((link, i) => {
                  return (
                    <>
                      <GlobalTextLinkExternal href={link.to} target={"_blank"}>
                        <GlobalDownloadWrapper>
                          <GlobalDownloadBadge src={link.img} />
                        </GlobalDownloadWrapper>
                      </GlobalTextLinkExternal>
                    </>
                  );
                })}
              </FooterLinksColumn>
            </FooterLinksWrapper>
          </FooterSectionWrapper>
          {/* <Divider onlyOnMobile /> */}
          <VerticalSpace vs={"25px"} />
          <FooterDownloadWrapperMobile>
            {footerDataDownload.links.map((link, i) => {
              return (
                <>
                  <GlobalTextLinkExternal href={link.to} target={"_blank"}>
                    <GlobalDownloadWrapper>
                      <GlobalDownloadBadge src={link.img} />
                    </GlobalDownloadWrapper>
                  </GlobalTextLinkExternal>
                </>
              );
            })}
          </FooterDownloadWrapperMobile>
          {/* <Divider /> */}
          <VerticalSpace vs={"25px"} />
          <GlobalSmallText center={"true"} light="true">
            © {new Date().getFullYear()}, Nuddle made with 🧠
          </GlobalSmallText>
        </FooterContentWrapper>
      </FooterContainer>
    </>
  );
}

export default Footer;

import React from "react";
import {
  GlobalDownloadBadge,
  GlobalDownloadWrapper,
} from "../../global_elements/DownloadElements";
import AppleBadge from "../../assets/apple_badge_black.svg";
import GooglePlayBadge from "../../assets/google_play_badge.png";
import {
  ImgWrap,
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ActionRow,
  Img,
  IconWrapper,
} from "./InfoElements";
import { aboutData } from "./Data";
import { GlobalTextLinkExternal } from "../../global_elements/LinkElements";

const InfoSection = ({
  lightBg,
  imgStart,
  topLine,
  lightText,
  headline,
  img,
  alt,
  showDownload,
  description,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine lightText={lightText}>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle lightText={lightText}>{description}</Subtitle>
                <ActionRow>
                  {showDownload ? (
                    <>
                      <GlobalDownloadWrapper
                        href={
                          "https://apps.apple.com/app/nuddle-number-sequences/id1643422440"
                        }
                        target={"_blank"}
                      >
                        <GlobalDownloadBadge src={AppleBadge} />
                      </GlobalDownloadWrapper>
                      <GlobalDownloadWrapper
                        href={
                          "https://play.google.com/store/apps/details?id=com.weiss.nuddle"
                        }
                        target={"_blank"}
                      >
                        <GlobalDownloadBadge src={GooglePlayBadge} />
                      </GlobalDownloadWrapper>
                    </>
                  ) : (
                    <>
                      {aboutData.map((data, i) => {
                        return (
                          <>
                            <GlobalTextLinkExternal
                              key={i}
                              href={data.href}
                              target={"_blank"}
                            >
                              <IconWrapper>{data.icon}</IconWrapper>
                            </GlobalTextLinkExternal>
                          </>
                        );
                      })}
                    </>
                  )}
                </ActionRow>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} isLeft={lightBg} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
